<template>
  <div class="root__">
    <b-button type="button" class="bttn"
    variant="no-color"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    @click="addNewPickupInfo">
        Add New Pickup Information
    </b-button>

    <div v-if="allPickupPackages.length == 0" class="Empty">
        <EmptyState />
    </div>
    <div v-else class="table-section">
      <b-table
        :hover="true"
        sticky-header="400px"
        responsive
        :items="allPickupPackages"
        :fields="fields"
        v-if="$mq === 'largeDevices'"
      >
      <!-- {{allPickupAddress}} -->
      <template v-for="field in allPickupPackages"
                 v-slot:cell(weight)="field">
                 {{field.item.pickupPackages[0].weight}}
      </template>

       <template v-for="field in allPickupPackages"
                 v-slot:cell(packageDimension)="field">
                 {{field.item.pickupPackages[0].packageDimension}}
       </template>

       <template v-for="field in allPickupPackages"
                 v-slot:cell(packageDescription)="field">
                 {{field.item.pickupPackages[0].packageDescription}}
       </template>

       <template  v-slot:cell(shippingAddress)="field"> 
                  <div v-if="field.item.shipDirectly">
                    Shipping Directly
                  </div>
                  <div v-else>
                    Shipping to YIC Warehouse
                  </div>
        </template>

       <template v-for="field in allPickupPackages"
                 v-slot:cell(address)="field">
                 {{field.item.pickupAddress[0].addressLine1}},
                 {{field.item.pickupAddress[0].addressLine2}},
                 {{field.item.pickupAddress[0].zipCode}},
                 {{field.item.pickupAddress[0].city}},
                 {{field.item.pickupAddress[0].state}},
                 {{field.item.pickupAddress[0].country}},
       </template>

       <template v-for="field in allPickupPackages"
                 v-slot:cell(pickupStatus)="field">
                 <div v-if="field.item.pickupPackages[0].pickupStatus == 'delivered'" class='green' >Delivered</div>
                 <div v-else-if="field.item.pickupPackages[0].pickupStatus == 'inProgress' " class='orange' >In-Progress</div>
                 <div v-else-if="field.item.pickupPackages[0].pickupStatus == 'Requested' " class="">Requested</div>
       </template>

       <template v-for="field in allPickupPackages"
                 v-slot:cell(trackingNumber)="field">
                 {{field.item.pickupPackages[0].trackingNumber}}
       </template>

      </b-table>

      <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
        <div v-for="item in allPickupPackages" class="card pickup">
          <div class="c_left">
            <div class="pkgDesc">Description:- {{item.pickupPackages[0].packageDescription}}</div>
            <div>
            </div>
            <div>Weight:- {{item.pickupPackages[0].weight}}</div>
            <div>Dimensions:- {{item.pickupPackages[0].packageDimension}}</div>
            <div class="deliveryStat"><span v-if="item.shipDirectly">Shipping Directly</span>
              <span v-else>Shipping to YIC Warehouse</span>
            </div>
          </div>
          <div class="c_right">
            <div class="status">
                 <div v-if="item.pickupPackages[0].pickupStatus == 'delivered'" class='green' >Delivered</div>
                 <div v-else-if="item.pickupPackages[0].pickupStatus == 'inProgress' " class='orange' >In-Progress</div>
                 <div v-else-if="item.pickupPackages[0].pickupStatus == 'Requested' " class="">Requested</div>
            </div>
            <div class="trackingNo">{{item.pickupPackages[0].trackingNumber}}</div>
            <div class="contactNo"><span class="phone_text">Ph:- </span> {{item.contactNumber}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BTable, BButton } from "bootstrap-vue";
import { mapState, mapMutations, mapActions } from "vuex";
import EmptyState from './EmptyStatePickup.vue';
import { required } from "@validations";
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity,
    deliveryAddressId: '',
    al1: '',
    al2: '',
    zipCode: '',
    city: '',
    phone: '',
    val2: '',
    senderContact: '',
  }
})

export default {
  components: {
    BTable,
    ValidationProvider,
    ValidationObserver,
    BButton,
    EmptyState
  },
  data() {
    return {
      fields: [
        { label: "Weight", key: "weight" },
        { label: "Package Dimension", key: "packageDimension" },
        { label: "Package Description", key: "packageDescription" },
        { label: "Address", key: "address" },
        { label: "Phone", key: "contactNumber" },
        { label: "Delivery", key: "shippingAddress" },
        { label: "Pickup-Status", key: "pickupStatus" },
        { label: "Tracking Details", key: "trackingNumber" },
      ],
    };
  },
  directives: {
    Ripple,
  },
  computed:{ 
    ...mapState({
    allPickupPackages: (state) => {
      return state.pickUpStore.pickupPackagesDetails;
    },
    getAddrL1: (state) => {
        return state.senderDetails.addrL1;
      },
      getAddrL2: (state) => {
        return state.senderDetails.addrL2;
      },
      st: (state) => {
        return state.deliveryDestination.stt;
      },
      country: (state) => {
        return state.deliveryDestination.country;
      },
    warehouseAddress: state => {
      return state.warehouseAddress.warehouseComponents;
      },
    defaultAddress: state => {
      return state.address.addresses
      }
   }),
  },
  methods: {
    ...mapActions({
      findAllPickupPackages: "pickUpStore/getPickUpPackages",
      getWarehouseAddress: "warehouseAddress/getWarehouseAddress",
      getDeliveryDestinations: "deliveryDestination/getAllAddresses",
      
    }),
    ...mapMutations({
        setSidebarRoute: "routesStore/SET_SIDEBAR",
        setMyOrdersNavbar: "routesStore/ SET_MY_ORDERS_NAVBAR",
        setNavbarRoute: "routesStore/SET_NAVBAR",
    }),
    addNewPickupInfo() {
        this.$router.push({ name: 'pickupDetails' })
    }
  },
  async mounted() {
    await this.findAllPickupPackages();
    this.setSidebarRoute("pickup");
    this.setMyOrdersNavbar("");
    this.setNavbarRoute("dashboardLanding");
  },
};
</script>

<style scoped>
.table-section{
  margin-left: 4rem;
}
.Empty {
    margin: 4rem;
}
.orange {
    color: #F68A22;
}
.blue {
    color: #4385F5;
}
.green {
    color: #10A531;
}
.bttn {
    margin: 4rem;
    background-color: #4385F5;
    border: none;
    color: white;
}

@media screen and (max-width: 1200px){
  .root__ {
    background: white;
    margin: 1rem 0rem 0rem 0rem;
  }
  .table-section{
    background: white;
    margin-left: 0rem;
    padding: 1rem;
    height: 100%;
  }
  .pickup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 85px;
    padding: 0.6rem;
    margin-bottom: 0.5rem;
  }
  .c_left {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .c_right {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .bttn {
    margin: 1rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    background-color: #4385F5;
    border: none;
    color: white;
    width: 92%;
  }
  .trackingNo {
    display: flex;
    justify-content: flex-end;
    color: #4385F5;
  }
  .status {
    display: flex;
    justify-content: flex-end;
  }
  .pkgDesc {
    font-size: 1rem;
    font-weight: 500;
  }
  .contactNo{
    display: flex;
    justify-content: flex-end;
  }
  .phone_text{
    margin-right: 0.2rem;
  }
  .deliveryStat {
    font-weight: bold;
  }
}
</style>